
import { Component, Vue, Prop } from "vue-property-decorator";
import { bus } from "@/main";

@Component
export default class DossierSettingsDropdown extends Vue {
    @Prop() activity!: Activity;

    bus = bus;

    show = false;

    handleClose() {
        this.show = false;
    }
}
